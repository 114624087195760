import UserSchema from '~~/app/auth/schemas/UserSchema';
import UserCollection from '~/app/auth/collections/UserCollection';
import EndpointFactory from '~~/app/factories/EndpointFactory';
import UserEndpoint from '~~/app/auth/endpoints/UserEndpoint';
import TenantEndpoint from '~~/app/auth/endpoints/TenantEndpoint';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import User from '~~/app/auth/models/User';

export default async (): Promise<string | void> => {
    const { setTenant } = useAuthStore();

    const endpoint = EndpointFactory.make(ModelType.TENANTS) as TenantEndpoint;
    const response = await endpoint.default();

    /**
     * The user doesn't have a tenant, redirect to the tenant registration page (default behaviour).
     */
    if (response && response.response && response.response.status == 404) {
        return;
    }

    /**
     * A specific error was thrown, redirect to the appropriate page.
     */
    if (response.error) {
        console.error('Failed to retrieve tenant.', response, response.error, Array.isArray(response.error));
        if (Array.isArray(response.error)) {
            const error = response.error[0];
            const tenantId = error.meta?.tenant;
            if (tenantId && error.detail == 'tenant_subscription_cancelled') {
                return `/auth/tenant/${tenantId}/cancelled`;
            }
            if (tenantId && error.detail == 'tenant_user_inactive') {
                return `/auth/tenant/${tenantId}/inactive`;
            }
        }
    }

    if (!response.data) {
        return `/auth/tenant/error`;
    }
    setTenant(response.data);

    // let users = authStore.tenant?.included.map(tenantUser => {
    //     tenantUser.type = 'users';
    //     return tenantUser;
    // });
    // authStore.users = new UserCollection(users);
};
